/* override: prism-rbo */

.token.keyword, .token.function {
  color: var(--syntax-keyword) !important; }

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string, .token.variable, .token.punctuation {
  color: var(--syntax-punctuation) !important; }

.token.boolean, .token.number, .token.class-name {
  color: var(--syntax-number) !important; }

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: var(--syntax-string) !important; }

.token.property, .token.tag, .token.constant, .token.symbol, .token.deleted {
  color: var(--syntax-main) !important; }

.snippet-box.response .snippet-body .code-toolbar {
  .toolbar {
    display: none; }

  code[class*="language-"]::-webkit-scrollbar-thumb, pre[class*="language-"]::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: none;
    background-color: var(--code-response-details); } }

.snippet-box.from-md .snippet-body .code-toolbar {
  max-width: 900px; }

.snippet-body .code-toolbar {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: calc(100% - .6rem);

  @media (max-width: 1280px) {
    max-width: 800px; }

  .toolbar {
    display: flex;
    flex-direction: row;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    order: 0;
    position: absolute;
    top: unset;
    bottom: calc(100% + .8rem);
    right: .5rem;
    margin: .5rem 0 .5rem 0;

    .toolbar-item {
      select {
        height: 30px;
        width: 80px;
        border-radius: 10px;
        border: 1px solid var(--code-api-details);
        color: var(--code-api-title);
        // background-color: var(--code-api-bg)
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: url('../images/select-code.svg') 94% / 15% no-repeat var(--code-api-bg);
        padding: 0 .3rem 0 .5rem;
        cursor: pointer;
        transition: all .3s;
        &:focus {
          outline: none; }
        &:hover {
          border: 1px solid var(--accent-violet); }
        &:active {
          border: 1px solid var(--code-response-details);
          &:focus {
            outline: none; } } }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        padding: 0 .5rem 0 .5rem;
        cursor: default; }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        color: var(--text-section);
        background-color: var(--code-api-bg);
        border: 1px solid var(--code-api-bg);
        cursor: pointer;
        margin-left: .5rem;
        box-shadow: none;
        transition: all .3s;
        &:hover {
          border: 1px solid var(--accent-violet);
          // background-color: var(--code-api-details)
          overflow: visible;
          -webkit-overflow-scrolling: touch; }
        &:focus {
          outline: none; }
        i {
          width: 30px;
          display: block;
          text-align: center;
          vertical-align: middle;
          position: absolute;
          font-size: 16px;
          color: var(--accent-azure);
          line-height: 30px;
          opacity: 0;
          right: -35px;
          transition: all .3s ease-in-out;
          &.show {
            opacity: 1;
            right: -1px;
            &.fa-check {
              color: var(--accent-green) !important; }
            &.fa-clipboard-list {
              color: var(--accent-azure) !important; } } } } } }

  code[class*="language-"], pre[class*="language-"] {
    background: transparent;
    // width: 100%
    max-height: 500px;
    min-width: 0px;
    max-width: -webkit-fill-available;
    color: var(--syntax-main); }

  code[class*="language-"]::-webkit-scrollbar-thumb, pre[class*="language-"]::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--code-api-details);
    border: none;

    .token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
      color: var(--syntax-string); } }

  .line-numbers {
    order: 1;
    margin: 0;
    border-radius: 0; } }
