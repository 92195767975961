// DARK MODE DEFAULT

body, body.dark-mode {
  --main-bg: #252729;
  --code-api-bg: #282c2f;
  --code-api-details: #36393c;
  --code-api-title: #c3ccd4;
  --code-response-bg: #282c2f;
  --code-response-details: #36393c;
  --code-response-title: #c3ccd4;
  --border-modules: #404f5d;
  --title-section: #7a94e0;
  --subtitle-section: #9362bf;
  --title-list: #7a94e0;
  --text-section: #bbcad4;

  --accent-azure: #00bcd4;
  --accent-yellow: #ffc107;
  --accent-violet: #c3a1ff;
  --accent-green: #8bc34a;

  --syntax-main: #a0adc5;
  --syntax-string: #bbda7e;
  --syntax-number: #ff5c94;
  --syntax-keyword: #00abff;
  --syntax-punctuation: #b489bb;

  --attribute-label: #ffffff;

  --method-get: #5791ce;
  --method-head: #00BCD4;
  --method-post: #b5cc8a;
  --method-put: #d89a3e;
  --method-delete: #ef6a6a;
  --method-connect: #f15aa5;
  --method-option: #e89be8;
  --method-trace: #ce9777;
  --method-patch: #a66dff; }


// LIGHT MODE

body.light-mode {
  --main-bg: #eceff3;
  --code-api-bg: #f0f2f5;
  --code-api-details: #e3e8ed;
  --code-api-title: #404f5d;
  --code-response-bg: #f0f2f5;
  --code-response-details: #dee3e8;
  --code-response-title: #404f5d;
  --border-modules: #cad3dc;
  --title-section: #5489bb;
  --subtitle-section: #9362bf;
  --title-list: #5489bb;
  --text-section: #404f5d;

  --accent-azure: #4889da;
  --accent-yellow: #ffc107;
  --accent-violet: #c3a1ff;
  --accent-green: #8bc34a;

  --syntax-main: #607d8b;
  --syntax-string: #697bde;
  --syntax-number: #ff5c94;
  --syntax-keyword: #00abff;
  --syntax-punctuation: #b489bb;

  --attribute-label: #4d5673;

  --method-get: #5791ce;
  --method-head: #29b2ca;
  --method-post: #77af36;
  --method-put: #d89a3e;
  --method-delete: #ef6a6a;
  --method-connect: #f15aa5;
  --method-option: #d06cd0;
  --method-trace: #ce9777;
  --method-patch: #a66dff; }
