@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i&display=swap");
body, body.dark-mode {
  --main-bg: #252729;
  --code-api-bg: #282c2f;
  --code-api-details: #36393c;
  --code-api-title: #c3ccd4;
  --code-response-bg: #282c2f;
  --code-response-details: #36393c;
  --code-response-title: #c3ccd4;
  --border-modules: #404f5d;
  --title-section: #7a94e0;
  --subtitle-section: #9362bf;
  --title-list: #7a94e0;
  --text-section: #bbcad4;
  --accent-azure: #00bcd4;
  --accent-yellow: #ffc107;
  --accent-violet: #c3a1ff;
  --accent-green: #8bc34a;
  --syntax-main: #a0adc5;
  --syntax-string: #bbda7e;
  --syntax-number: #ff5c94;
  --syntax-keyword: #00abff;
  --syntax-punctuation: #b489bb;
  --attribute-label: #ffffff;
  --method-get: #5791ce;
  --method-head: #00BCD4;
  --method-post: #b5cc8a;
  --method-put: #d89a3e;
  --method-delete: #ef6a6a;
  --method-connect: #f15aa5;
  --method-option: #e89be8;
  --method-trace: #ce9777;
  --method-patch: #a66dff; }

body.light-mode {
  --main-bg: #eceff3;
  --code-api-bg: #f0f2f5;
  --code-api-details: #e3e8ed;
  --code-api-title: #404f5d;
  --code-response-bg: #f0f2f5;
  --code-response-details: #dee3e8;
  --code-response-title: #404f5d;
  --border-modules: #cad3dc;
  --title-section: #5489bb;
  --subtitle-section: #9362bf;
  --title-list: #5489bb;
  --text-section: #404f5d;
  --accent-azure: #4889da;
  --accent-yellow: #ffc107;
  --accent-violet: #c3a1ff;
  --accent-green: #8bc34a;
  --syntax-main: #607d8b;
  --syntax-string: #697bde;
  --syntax-number: #ff5c94;
  --syntax-keyword: #00abff;
  --syntax-punctuation: #b489bb;
  --attribute-label: #4d5673;
  --method-get: #5791ce;
  --method-head: #29b2ca;
  --method-post: #77af36;
  --method-put: #d89a3e;
  --method-delete: #ef6a6a;
  --method-connect: #f15aa5;
  --method-option: #d06cd0;
  --method-trace: #ce9777;
  --method-patch: #a66dff; }

* {
  margin: 0;
  padding: 0; }

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent; }

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  right: .5rem;
  background-color: transparent !important;
  z-index: 999; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 1px solid var(--code-response-details);
  background-color: var(--code-response-bg);
  transition: all .3s; }

::-webkit-scrollbar-corner {
  background-color: transparent; }

body {
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
  background-color: var(--main-bg);
  color: var(--text-section);
  transition: all .3s;
  overflow: hidden;
  word-spacing: 2px; }
  body .postman-run-button {
    display: block;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 99;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5); }
    @media (max-width: 800px) {
      body .postman-run-button {
        display: none; } }
  body h1 {
    color: var(--title-section);
    text-align: left;
    margin: 1rem 0 1rem 0;
    font-weight: 200;
    line-height: 32px; }
  body h2 {
    color: var(--subtitle-section);
    text-align: left;
    margin: 2rem 0 .5rem 0;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase; }
    @media (max-width: 800px) {
      body h2 {
        margin: 4rem 0 .5rem 0; } }
  body h3 {
    color: var(--title-section);
    text-align: left;
    margin: 2rem 0 .5rem 0;
    font-weight: 400;
    font-size: 16px; }
  body h4 {
    color: var(--subtitle-section);
    text-align: left;
    margin: 2rem 0 .5rem 0;
    font-weight: 400;
    font-size: 14px; }
  body h5 {
    color: var(--subtitle-section); }
  body p, body ul, body ol {
    color: var(--text-section);
    text-align: justify;
    margin: 1rem 0 1rem 0; }
  body ul {
    padding-left: 2rem;
    list-style: disc;
    display: flex;
    flex-direction: column; }
  body ol {
    padding-left: 2rem;
    list-style: decimal;
    display: flex;
    flex-direction: column; }
  body a {
    text-decoration: none;
    color: var(--accent-azure);
    transition: color .3s;
    cursor: pointer;
    word-break: break-word;
    font-weight: 300; }
    body a:hover {
      color: var(--accent-violet); }
  body code[class*="language-"], body pre[class*="language-"] {
    text-shadow: none !important; }
  body select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; }
  body .api-version {
    position: fixed;
    top: -1px;
    right: 1rem; }
  body .api-version {
    height: auto;
    width: 100%;
    max-width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 .3rem 0 .3rem; }
    body .api-version select#version {
      height: 50px;
      width: 90%;
      margin: 0 .5rem .5rem .5rem;
      flex-grow: 1;
      border: 1px solid var(--code-response-details);
      background-color: var(--code-response-bg);
      background: url("../images/select-version.svg") 97%/22px no-repeat var(--code-api-bg);
      border-radius: 0 0 10px 10px;
      box-shadow: 0px 2px 6px 0px rgba(37, 39, 41, 0.5);
      padding-left: 1rem;
      padding-right: 2.5rem;
      color: var(--title-section);
      position: relative;
      direction: ltr;
      text-align: left; }
      body .api-version select#version:focus {
        outline: none; }
      body .api-version select#version option {
        direction: ltr;
        text-align: left;
        width: 100%; }
  body .hide {
    display: none !important; }
  body header {
    display: none; }
    @media (max-width: 800px) {
      body header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 100%;
        background-color: var(--main-bg);
        flex-shrink: 0;
        margin-top: 0px;
        position: fixed;
        z-index: 10;
        transition: all .4s;
        border-bottom: 1px solid var(--border-modules); }
        body header.large .logo-mobile {
          opacity: 1; }
        body header.large .top-header {
          opacity: 1; }
        body header.small {
          margin-top: -45px; }
          body header.small .logo-mobile {
            opacity: 0; }
          body header.small .top-header {
            opacity: 0; }
        body header .logo-mobile {
          height: 30px;
          width: auto;
          margin: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all .2s; }
          body header .logo-mobile.svg path {
            fill: var(--text-section); }
        body header .top-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: auto;
          position: relative; }
          body header .top-header .api-version-mobile {
            height: auto;
            width: 100%;
            max-width: fit-content;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 .3rem 0 .3rem; }
            body header .top-header .api-version-mobile select#version-mobile {
              height: 30px;
              width: 90%;
              margin: 0 .5rem .5rem .5rem;
              flex-grow: 1;
              border: 1px solid var(--code-response-details);
              background-color: var(--code-response-bg);
              background: url("../images/select-version.svg") 97%/22px no-repeat var(--code-api-bg);
              border-radius: 10px;
              box-shadow: 0px 2px 6px 0px rgba(37, 39, 41, 0.5);
              padding-left: 1rem;
              padding-right: 2.5rem;
              color: var(--title-section);
              position: relative;
              direction: ltr;
              text-align: left; }
              body header .top-header .api-version-mobile select#version-mobile:focus {
                outline: none; }
              body header .top-header .api-version-mobile select#version-mobile option {
                direction: ltr;
                text-align: left;
                width: 100%; }
        body header .top-mobile {
          height: 3.3rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 .3rem 0 .3rem; }
          body header .top-mobile select#mobile-menu {
            height: 30px;
            width: 90%;
            margin: .5rem;
            flex-grow: 1;
            border: 1px solid var(--code-response-details);
            background-color: var(--code-response-bg);
            background: url("../images/select-menu.svg") 3%/22px no-repeat var(--code-api-bg);
            border-radius: 10px;
            padding-left: 2.5rem;
            color: var(--title-section);
            text-transform: uppercase;
            position: relative;
            direction: ltr;
            text-align: left; }
            body header .top-mobile select#mobile-menu:focus {
              outline: none; }
            body header .top-mobile select#mobile-menu option {
              direction: ltr;
              text-align: left;
              width: 100%; }
          body header .top-mobile .cta-mobile {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center; }
            body header .top-mobile .cta-mobile .top-search-mobile {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 0 0 .5rem;
              width: calc(100% - 2rem); }
              body header .top-mobile .cta-mobile .top-search-mobile button.search-trigger-mobile {
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                padding: .5rem;
                margin: .5rem;
                align-items: center;
                border: 1px solid var(--code-response-details);
                background-color: var(--code-response-bg);
                border-radius: 10px;
                color: var(--title-section);
                cursor: pointer;
                transition: all .3s; }
                body header .top-mobile .cta-mobile .top-search-mobile button.search-trigger-mobile:hover {
                  border: 1px solid var(--code-response-bg); }
                body header .top-mobile .cta-mobile .top-search-mobile button.search-trigger-mobile:active {
                  border: 1px solid var(--code-response-details); }
                body header .top-mobile .cta-mobile .top-search-mobile button.search-trigger-mobile:focus {
                  outline: none; }
            body header .top-mobile .cta-mobile .theme-switch-wrapper {
              display: flex;
              align-items: center;
              margin: .5rem; }
              body header .top-mobile .cta-mobile .theme-switch-wrapper .theme-switch {
                display: inline-block;
                height: 14px;
                position: relative;
                width: 36px; }
                body header .top-mobile .cta-mobile .theme-switch-wrapper .theme-switch input[type="checkbox"] {
                  display: none; }
                body header .top-mobile .cta-mobile .theme-switch-wrapper .theme-switch .slider {
                  background-color: var(--code-response-bg);
                  border: 1px solid var(--code-response-details);
                  bottom: 0;
                  cursor: pointer;
                  left: 0;
                  position: absolute;
                  right: 0;
                  top: 0;
                  transition: .4s; }
                body header .top-mobile .cta-mobile .theme-switch-wrapper .theme-switch .slider:before {
                  background-color: var(--main-bg);
                  bottom: -5px;
                  height: 20px;
                  left: -3px;
                  position: absolute;
                  transition: .3s;
                  width: 20px;
                  display: flex;
                  justify-content: center;
                  align-items: center; }
                body header .top-mobile .cta-mobile .theme-switch-wrapper .theme-switch input:checked + .slider {
                  background-color: var(--code-response-bg); }
                body header .top-mobile .cta-mobile .theme-switch-wrapper .theme-switch input:checked + .slider:before {
                  transform: translateX(21px);
                  content: "\f185";
                  color: var(--accent-yellow);
                  font-family: 'Font Awesome 5 Free'; }
                body header .top-mobile .cta-mobile .theme-switch-wrapper .theme-switch .slider.round {
                  border-radius: 8px; }
                body header .top-mobile .cta-mobile .theme-switch-wrapper .theme-switch .slider.round:before {
                  border-radius: 50%;
                  border: 1px solid var(--code-response-details);
                  content: "\f186";
                  color: var(--accent-violet);
                  font-family: 'Font Awesome 5 Free'; } }
  body main {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: 600px; }
    body main #sidebar, body main #sidebar-changelog {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      min-height: 100%;
      min-width: 260px;
      width: 260px;
      border-right: 1px solid var(--border-modules);
      border-bottom: 1px solid var(--border-modules); }
      @media (max-width: 800px) {
        body main #sidebar, body main #sidebar-changelog {
          display: none; } }
      body main #sidebar .top, body main #sidebar-changelog .top {
        height: auto;
        width: calc(100% - .6rem);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 .3rem 0 .3rem; }
        body main #sidebar .top .logo, body main #sidebar-changelog .top .logo {
          height: 30px;
          width: auto;
          margin: .5rem;
          display: flex;
          justify-content: center;
          align-items: center; }
          body main #sidebar .top .logo.svg path, body main #sidebar-changelog .top .logo.svg path {
            fill: var(--text-section); }
        body main #sidebar .top .theme-switch-wrapper, body main #sidebar-changelog .top .theme-switch-wrapper {
          display: flex;
          align-items: center;
          margin: .5rem; }
          body main #sidebar .top .theme-switch-wrapper .theme-switch, body main #sidebar-changelog .top .theme-switch-wrapper .theme-switch {
            display: inline-block;
            height: 14px;
            position: relative;
            width: 36px; }
            body main #sidebar .top .theme-switch-wrapper .theme-switch input[type="checkbox"], body main #sidebar-changelog .top .theme-switch-wrapper .theme-switch input[type="checkbox"] {
              display: none; }
            body main #sidebar .top .theme-switch-wrapper .theme-switch .slider, body main #sidebar-changelog .top .theme-switch-wrapper .theme-switch .slider {
              background-color: var(--code-response-bg);
              border: 1px solid var(--code-response-details);
              bottom: 0;
              cursor: pointer;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              transition: .4s; }
            body main #sidebar .top .theme-switch-wrapper .theme-switch .slider:before, body main #sidebar-changelog .top .theme-switch-wrapper .theme-switch .slider:before {
              background-color: var(--main-bg);
              bottom: -5px;
              height: 20px;
              left: -3px;
              position: absolute;
              transition: .3s;
              width: 20px;
              display: flex;
              justify-content: center;
              align-items: center; }
            body main #sidebar .top .theme-switch-wrapper .theme-switch input:checked + .slider, body main #sidebar-changelog .top .theme-switch-wrapper .theme-switch input:checked + .slider {
              background-color: var(--code-response-bg); }
            body main #sidebar .top .theme-switch-wrapper .theme-switch input:checked + .slider:before, body main #sidebar-changelog .top .theme-switch-wrapper .theme-switch input:checked + .slider:before {
              transform: translateX(21px);
              content: "\f185";
              color: var(--accent-yellow);
              font-family: 'Font Awesome 5 Free'; }
            body main #sidebar .top .theme-switch-wrapper .theme-switch .slider.round, body main #sidebar-changelog .top .theme-switch-wrapper .theme-switch .slider.round {
              border-radius: 8px; }
            body main #sidebar .top .theme-switch-wrapper .theme-switch .slider.round:before, body main #sidebar-changelog .top .theme-switch-wrapper .theme-switch .slider.round:before {
              border-radius: 50%;
              border: 1px solid var(--code-response-details);
              content: "\f186";
              color: var(--accent-violet);
              font-family: 'Font Awesome 5 Free'; }
      body main #sidebar .side-search, body main #sidebar-changelog .side-search {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem 0 1rem;
        margin-bottom: .5rem;
        width: calc(100% - 2rem);
        border-bottom: 1px solid var(--border-modules); }
        body main #sidebar .side-search button.search-trigger, body main #sidebar-changelog .side-search button.search-trigger {
          height: 30px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: .5rem;
          margin: 1rem 0 1rem 0;
          align-items: center;
          border: 1px solid var(--code-response-details);
          background-color: var(--code-response-bg);
          border-radius: 10px;
          color: var(--title-section);
          cursor: pointer;
          transition: all .3s; }
          body main #sidebar .side-search button.search-trigger:hover, body main #sidebar-changelog .side-search button.search-trigger:hover {
            border: 1px solid var(--code-response-bg); }
          body main #sidebar .side-search button.search-trigger:active, body main #sidebar-changelog .side-search button.search-trigger:active {
            border: 1px solid var(--code-response-details); }
          body main #sidebar .side-search button.search-trigger:focus, body main #sidebar-changelog .side-search button.search-trigger:focus {
            outline: none; }
      body main #sidebar .sidebar-left-collapse, body main #sidebar-changelog .sidebar-left-collapse {
        width: calc(100% - .3rem);
        height: 100%;
        padding: 0;
        margin: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch; }
        body main #sidebar .sidebar-left-collapse .sidebar-links, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links {
          display: flex;
          flex-direction: column;
          width: calc(100% - .6rem);
          margin: 30px auto; }
          body main #sidebar .sidebar-left-collapse .sidebar-links .link, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: auto;
            width: 100%; }
            body main #sidebar .sidebar-left-collapse .sidebar-links .link.selected a, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link.selected a {
              color: var(--title-section);
              line-height: 1.5;
              margin: .5rem 0 .5rem 0;
              padding: .3rem 0 .3rem 0;
              border: 1px solid var(--accent-violet);
              border-radius: 22px; }
              body main #sidebar .sidebar-left-collapse .sidebar-links .link.selected a i, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link.selected a i {
                color: var(--title-section); }
            body main #sidebar .sidebar-left-collapse .sidebar-links .link a, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link a {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              text-decoration: none;
              margin: .25rem 0 .25rem 0;
              padding: .1rem 0 .1rem 0;
              text-align: left;
              color: var(--text-section);
              font-size: 14px;
              line-height: 2;
              border: 1px solid var(--code-response-details);
              background-color: var(--code-response-bg);
              border-radius: 20px;
              transition: all .3s; }
              body main #sidebar .sidebar-left-collapse .sidebar-links .link a:hover, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link a:hover {
                border: 1px solid var(--accent-violet);
                color: var(--title-section); }
              body main #sidebar .sidebar-left-collapse .sidebar-links .link a:active, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link a:active {
                border: 1px solid var(--accent-violet);
                color: var(--text-section); }
                body main #sidebar .sidebar-left-collapse .sidebar-links .link a:active i, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link a:active i {
                  color: var(--text-section); }
              body main #sidebar .sidebar-left-collapse .sidebar-links .link a svg, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link a svg {
                margin: 0 1rem 0 1rem;
                color: var(--title-section); }
              body main #sidebar .sidebar-left-collapse .sidebar-links .link a i, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link a i {
                margin: 0 0 0 1rem;
                color: var(--text-section); }
              body main #sidebar .sidebar-left-collapse .sidebar-links .link a span, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link a span {
                margin-left: 1rem;
                display: flex;
                flex-wrap: wrap; }
            body main #sidebar .sidebar-left-collapse .sidebar-links .link ul.sub-links, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link ul.sub-links {
              display: flex;
              flex-direction: column;
              max-height: 0;
              width: 100%;
              overflow: hidden;
              list-style: none;
              background-color: transparent;
              color: var(--text-section);
              font-size: 12px;
              line-height: 24px;
              padding: 0;
              margin: 0;
              transition: 0.5s; }
              body main #sidebar .sidebar-left-collapse .sidebar-links .link ul.sub-links li, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link ul.sub-links li {
                background-color: transparent;
                margin: 0; }
                body main #sidebar .sidebar-left-collapse .sidebar-links .link ul.sub-links li.selected a, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link ul.sub-links li.selected a {
                  color: var(--text-title);
                  background-color: var(--code-response-bg); }
                body main #sidebar .sidebar-left-collapse .sidebar-links .link ul.sub-links li a, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link ul.sub-links li a {
                  text-decoration: none;
                  background-color: transparent;
                  color: var(--text-section);
                  display: block;
                  padding: .3rem 0 .3rem 0;
                  margin: .3rem 0 .3rem 0;
                  border: 0;
                  text-align: left;
                  background-color: var(--main-bg);
                  border-radius: 20px;
                  transition: all .3s; }
                  body main #sidebar .sidebar-left-collapse .sidebar-links .link ul.sub-links li a:hover, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link ul.sub-links li a:hover {
                    color: var(--text-title);
                    background-color: var(--code-response-bg); }
                  body main #sidebar .sidebar-left-collapse .sidebar-links .link ul.sub-links li a:active, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link ul.sub-links li a:active {
                    color: var(--text-section);
                    background-color: var(--code-response-bg); }
                  body main #sidebar .sidebar-left-collapse .sidebar-links .link ul.sub-links li a span, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link ul.sub-links li a span {
                    margin-left: 1rem;
                    display: flex;
                    flex-wrap: wrap; }
            body main #sidebar .sidebar-left-collapse .sidebar-links .link.selected ul.sub-links, body main #sidebar-changelog .sidebar-left-collapse .sidebar-links .link.selected ul.sub-links {
              max-height: 2000px; }
      body main #sidebar .copyright, body main #sidebar-changelog .copyright {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 32px;
        width: 260px;
        border-top: 1px solid var(--border-modules); }
        body main #sidebar .copyright span, body main #sidebar-changelog .copyright span {
          font-size: 12px;
          color: var(--text-section); }
    body main #content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      padding-top: 2rem;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch; }
      body main #content .api-section {
        width: 100%;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0 1rem 0;
        border-bottom: 1px solid var(--border-modules); }
        body main #content .api-section .api-module {
          width: 100%;
          max-width: calc(1264px + 10vw);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 4rem 0 4rem 0; }
          @media (max-width: 1280px) {
            body main #content .api-section .api-module {
              flex-direction: column; } }
          @media (max-width: 800px) {
            body main #content .api-section .api-module {
              padding: 2rem 0 4rem 0; } }
          body main #content .api-section .api-module table {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: auto;
            width: 100%;
            max-width: 900px;
            margin: 1rem 2.5rem 1rem 2.5rem;
            padding: 0; }
            @media (max-width: 1280px) {
              body main #content .api-section .api-module table {
                margin: 1rem 0 1rem 0; } }
            body main #content .api-section .api-module table thead {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              padding: .3rem 0 .3rem 0;
              border-radius: 10px 10px 0 0;
              border: 1px solid var(--code-api-details);
              background-color: var(--code-api-details); }
              body main #content .api-section .api-module table thead tr {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%; }
                body main #content .api-section .api-module table thead tr th {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  min-width: 33%;
                  margin: .5rem; }
            body main #content .api-section .api-module table tbody {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              flex-direction: column;
              flex-grow: 1;
              width: 100%;
              height: auto;
              position: relative;
              padding: 1rem 0 1rem 0;
              border-radius: 0 0 10px 10px;
              border: 1px solid var(--code-api-details);
              background-color: var(--code-api-bg); }
              body main #content .api-section .api-module table tbody tr {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%; }
                body main #content .api-section .api-module table tbody tr td {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  text-align: justify;
                  min-width: 33%;
                  margin: .5rem; }
                  body main #content .api-section .api-module table tbody tr td code {
                    word-break: break-all; }
          body main #content .api-section .api-module .text-side {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            flex-shrink: 1;
            height: 100%;
            padding: 0 0 3rem 0; }
            @media (max-width: 1280px) {
              body main #content .api-section .api-module .text-side {
                width: 100%; } }
            body main #content .api-section .api-module .text-side .text-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              height: auto;
              width: 90%;
              max-width: 600px;
              margin: 0 2.5rem 0 2.5rem;
              font-size: 14px; }
              body main #content .api-section .api-module .text-side .text-box h1 {
                margin-top: 3rem; }
              body main #content .api-section .api-module .text-side .text-box.changelog, body main #content .api-section .api-module .text-side .text-box.full-centered {
                max-width: 1200px;
                margin: 2rem 0 2rem 0; }
                @media (max-width: 800px) {
                  body main #content .api-section .api-module .text-side .text-box.changelog ul, body main #content .api-section .api-module .text-side .text-box.full-centered ul {
                    padding: 0 0 0 1.5rem;
                    max-width: 100%; }
                    body main #content .api-section .api-module .text-side .text-box.changelog ul li, body main #content .api-section .api-module .text-side .text-box.full-centered ul li {
                      width: 100%; }
                      body main #content .api-section .api-module .text-side .text-box.changelog ul li p, body main #content .api-section .api-module .text-side .text-box.full-centered ul li p {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        text-align: left;
                        flex-wrap: wrap;
                        line-height: 24px;
                        width: 100%; } }
              @media (max-width: 1280px) {
                body main #content .api-section .api-module .text-side .text-box {
                  max-width: 800px; } }
              body main #content .api-section .api-module .text-side .text-box code[class*="language-"], body main #content .api-section .api-module .text-side .text-box pre[class*="language-"] {
                background: var(--code-response-bg);
                border: 1px solid var(--code-response-details);
                color: var(--code-response-title);
                margin: 0 .3rem 0 0;
                font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace; }
              body main #content .api-section .api-module .text-side .text-box code[class*="language-"] {
                padding: 0 .5rem 0 .5rem; }
              body main #content .api-section .api-module .text-side .text-box pre[class*="language-"] {
                padding: 0; }
              body main #content .api-section .api-module .text-side .text-box ul.argattr-box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: auto;
                width: 100%;
                margin: 1rem 0 1rem 0;
                padding: 0; }
                body main #content .api-section .api-module .text-side .text-box ul.argattr-box li {
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  width: 100%;
                  height: auto;
                  padding: 1rem 0 1rem 0; }
                  body main #content .api-section .api-module .text-side .text-box ul.argattr-box li.title {
                    text-transform: uppercase;
                    border-bottom: 1px solid var(--border-modules); }
                    body main #content .api-section .api-module .text-side .text-box ul.argattr-box li.title h5 {
                      font-size: 12px;
                      font-weight: 300; }
                  @media (max-width: 800px) {
                    body main #content .api-section .api-module .text-side .text-box ul.argattr-box li.child {
                      flex-direction: column; }
                      body main #content .api-section .api-module .text-side .text-box ul.argattr-box li.child h3 {
                        flex-direction: row; } }
                  body main #content .api-section .api-module .text-side .text-box ul.argattr-box li h3 {
                    width: 100%;
                    max-width: 300px;
                    padding-right: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin: 0; }
                    body main #content .api-section .api-module .text-side .text-box ul.argattr-box li h3 span.label {
                      font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
                      margin: 0 .5rem 0 0;
                      font-size: 13px;
                      color: var(--attribute-label);
                      font-weight: bolder; }
                    body main #content .api-section .api-module .text-side .text-box ul.argattr-box li h3 span.validation {
                      margin: .2rem 0 .2rem 0;
                      font-size: 12px;
                      font-weight: 300;
                      line-height: 16px; }
                  body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description > ul > li:before {
                    content: '◦';
                    margin-right: 1rem; }
                  body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-grow: 1;
                    width: 100%; }
                    body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description p {
                      margin: 0 0 1rem 0;
                      flex-wrap: wrap;
                      word-break: break-word; }
                    body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer {
                      display: block;
                      height: auto;
                      margin: 0;
                      width: 100%; }
                      body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.question {
                        position: relative;
                        width: 100%;
                        padding: .5rem 0 .5rem 0;
                        cursor: pointer;
                        display: flex;
                        align-items: center; }
                        body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.question span {
                          color: var(--title-section);
                          flex-grow: 1; }
                        body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.question i {
                          color: var(--accent-violet);
                          transform: rotate(0deg);
                          transition: all .3s;
                          font-size: 16px;
                          font-family: 'Font Awesome 5 Free'; }
                        body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.question:after {
                          display: block;
                          content: "";
                          position: absolute;
                          top: 100%;
                          left: 0;
                          width: 100%;
                          border: 0px solid var(--border-modules);
                          transition: all .3s ease; }
                      body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer__radio:checked ~ .answer.question:after {
                        top: 0; }
                      body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer__radio:checked ~ .answer.content {
                        max-height: 10000px; }
                      body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer__radio:checked ~ .answer.question i {
                        transform: rotate(45deg);
                        color: lightcoral; }
                      body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content {
                        font-size: 16px;
                        border-bottom: 1px solid var(--border-modules);
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height .5s ease; }
                        body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner {
                          padding: 1rem 0 1rem 0; }
                          body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner p:not(:first-child), body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul:not(:first-child) {
                            margin: 1em 0; }
                          body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner p:last-child, body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul:last-child {
                            margin-bottom: 0; }
                          body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            height: auto;
                            width: auto;
                            margin: 1rem 0 1rem 0;
                            padding: 0;
                            background-color: var(--code-response-details);
                            border: 1px solid var(--border-modules);
                            border-radius: 10px;
                            list-style: none; }
                            body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li {
                              display: flex;
                              justify-content: center;
                              align-items: flex-start;
                              flex-direction: column;
                              width: calc(100% - 2rem);
                              height: auto;
                              padding: .5rem 1rem .5rem 1rem;
                              background-color: var(--code-response-details);
                              font-size: 13px; }
                              body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li:nth-child(odd) {
                                background-color: var(--code-response-bg); }
                              body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li:first-child {
                                border-radius: 10px 10px 0 0; }
                              body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li:last-child {
                                border-radius: 0 0 10px 10px; }
                              body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li:only-child {
                                border-radius: 10px 10px 10px 10px; }
                              body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li .top-item {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                width: 100%;
                                font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace; }
                                body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li .top-item span.detail {
                                  margin: 0 .2rem 0 .5rem;
                                  font-size: 12px;
                                  font-weight: 500; }
                                body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li .top-item span.label {
                                  font-weight: bolder;
                                  color: var(--attribute-label); }
                              body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li .bottom-item {
                                width: 100%; }
                                body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li .bottom-item ul {
                                  padding: 0px; }
                              body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li ul {
                                margin: 1rem auto 1rem auto; }
                                body main #content .api-section .api-module .text-side .text-box ul.argattr-box li .description .answer.content .answer.content-inner ul.accordion li ul li {
                                  background-color: var(--code-response-bg);
                                  border-bottom: 1px solid var(--border-modules); }
              body main #content .api-section .api-module .text-side .text-box ul.changelog {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: auto;
                width: 100%;
                max-width: 900px;
                margin: 1rem 0 1rem 0;
                padding: 0;
                border: 1px solid var(--border-modules);
                border-radius: 10px;
                list-style: none; }
                body main #content .api-section .api-module .text-side .text-box ul.changelog li {
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-start;
                  width: 100%;
                  height: auto;
                  padding: 1rem 0 1rem 0;
                  background-color: var(--code-response-details); }
                  body main #content .api-section .api-module .text-side .text-box ul.changelog li:nth-child(odd) {
                    background-color: var(--code-response-bg); }
                  body main #content .api-section .api-module .text-side .text-box ul.changelog li:last-child {
                    border-radius: 0 0 10px 10px; }
                  body main #content .api-section .api-module .text-side .text-box ul.changelog li.title-changelog {
                    text-transform: uppercase;
                    border-bottom: 1px solid var(--border-modules);
                    border-radius: 10px 10px 0 0;
                    background-color: transparent; }
                    body main #content .api-section .api-module .text-side .text-box ul.changelog li.title-changelog h5 {
                      text-align: left;
                      word-break: break-word;
                      min-width: 90px;
                      width: 100%;
                      font-size: 12px;
                      font-weight: 300;
                      padding-left: 1rem; }
                  body main #content .api-section .api-module .text-side .text-box ul.changelog li span {
                    font-weight: 500;
                    width: 100%;
                    min-width: 120px;
                    text-align: left;
                    word-break: break-word;
                    padding-left: 1rem; }
          body main #content .api-section .api-module .snippet-side {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            align-self: flex-start;
            max-width: 50%;
            flex-grow: 1;
            height: auto;
            padding: 4rem 0 4rem 0;
            position: sticky;
            top: 0; }
            @media (max-width: 1280px) {
              body main #content .api-section .api-module .snippet-side {
                width: 100%;
                max-width: 100%; } }
          body main #content .api-section .api-module .snippet-side .snippet-box, body main #content .api-section .api-module .text-side .snippet-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
            max-width: 600px;
            min-width: 300px;
            height: auto;
            margin: 1rem 2.5rem 1rem 2.5rem;
            background-color: var(--code-api-bg);
            border-radius: 10px;
            box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.2); }
            body main #content .api-section .api-module .snippet-side .snippet-box.from-md, body main #content .api-section .api-module .text-side .snippet-box.from-md {
              max-width: 900px; }
            @media (max-width: 1280px) {
              body main #content .api-section .api-module .snippet-side .snippet-box, body main #content .api-section .api-module .text-side .snippet-box {
                max-width: 800px;
                margin: 1rem 0 1rem 0;
                width: 90%;
                min-width: 100px; }
                body main #content .api-section .api-module .snippet-side .snippet-box.from-md, body main #content .api-section .api-module .text-side .snippet-box.from-md {
                  width: 100%;
                  max-width: 100%; } }
            body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head, body main #content .api-section .api-module .text-side .snippet-box .snippet-head {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              width: 100%;
              border-radius: 10px 10px 0 0;
              border: 1px solid var(--code-api-details);
              background-color: var(--code-api-details); }
              body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head p.response, body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head p.path, body main #content .api-section .api-module .text-side .snippet-box .snippet-head p.response, body main #content .api-section .api-module .text-side .snippet-box .snippet-head p.path {
                margin: .5rem 0 .5rem .5rem;
                color: var(--code-api-title);
                white-space: pre;
                text-overflow: ellipsis;
                width: calc(100% - 180px);
                overflow: hidden; }
              body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: .5rem 0 .5rem .5rem;
                font-weight: bolder;
                font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace; }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method.get, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method.get {
                  color: var(--method-get); }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method.head, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method.head {
                  color: var(--method-head); }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method.post, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method.post {
                  color: var(--method-post); }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method.put, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method.put {
                  color: var(--method-put); }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method.delete, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method.delete {
                  color: var(--method-delete); }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method.connect, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method.connect {
                  color: var(--method-connect); }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method.option, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method.option {
                  color: var(--method-option); }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method.trace, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method.trace {
                  color: var(--method-trace); }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-head span.method.patch, body main #content .api-section .api-module .text-side .snippet-box .snippet-head span.method.patch {
                  color: var(--method-patch); }
            body main #content .api-section .api-module .snippet-side .snippet-box .snippet-body, body main #content .api-section .api-module .text-side .snippet-box .snippet-body {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              flex-grow: 1;
              width: 100%;
              height: auto;
              max-height: 600px;
              position: relative;
              padding: 1rem 0 1rem 0;
              border-radius: 0 0 10px 10px;
              border: 1px solid var(--code-api-details);
              background-color: var(--code-api-bg); }
              body main #content .api-section .api-module .snippet-side .snippet-box .snippet-body.no-head, body main #content .api-section .api-module .text-side .snippet-box .snippet-body.no-head {
                border-radius: 10px; }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-body.no-head .code-toolbar pre, body main #content .api-section .api-module .text-side .snippet-box .snippet-body.no-head .code-toolbar pre {
                  border: 0 !important; }
                  body main #content .api-section .api-module .snippet-side .snippet-box .snippet-body.no-head .code-toolbar pre code, body main #content .api-section .api-module .text-side .snippet-box .snippet-body.no-head .code-toolbar pre code {
                    border: 0 !important; }
              body main #content .api-section .api-module .snippet-side .snippet-box .snippet-body .copy-tooltip, body main #content .api-section .api-module .text-side .snippet-box .snippet-body .copy-tooltip {
                height: auto;
                width: auto;
                padding: .3rem 1.5rem .3rem 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid var(--border-modules);
                border-radius: 20px;
                background-color: var(--code-response-bg);
                position: absolute;
                top: -74px;
                right: 7px;
                opacity: 0;
                transition: all .2s ease; }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-body .copy-tooltip:after, body main #content .api-section .api-module .text-side .snippet-box .snippet-body .copy-tooltip:after {
                  content: "";
                  position: absolute;
                  bottom: -6px;
                  right: 15px;
                  width: 0;
                  height: 0;
                  border-left: solid 6px transparent;
                  border-right: solid 6px transparent;
                  border-top: solid 6px var(--border-modules); }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-body .copy-tooltip.appear, body main #content .api-section .api-module .text-side .snippet-box .snippet-body .copy-tooltip.appear {
                  top: -84px;
                  opacity: 1; }
                body main #content .api-section .api-module .snippet-side .snippet-box .snippet-body .copy-tooltip span, body main #content .api-section .api-module .text-side .snippet-box .snippet-body .copy-tooltip span {
                  font-size: 12px; }
              body main #content .api-section .api-module .snippet-side .snippet-box .snippet-body .code-toolbar .token, body main #content .api-section .api-module .text-side .snippet-box .snippet-body .code-toolbar .token {
                word-break: keep-all; }

/* override: prism-rbo */
.token.keyword, .token.function {
  color: var(--syntax-keyword) !important; }

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string, .token.variable, .token.punctuation {
  color: var(--syntax-punctuation) !important; }

.token.boolean, .token.number, .token.class-name {
  color: var(--syntax-number) !important; }

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: var(--syntax-string) !important; }

.token.property, .token.tag, .token.constant, .token.symbol, .token.deleted {
  color: var(--syntax-main) !important; }

.snippet-box.response .snippet-body .code-toolbar .toolbar {
  display: none; }

.snippet-box.response .snippet-body .code-toolbar code[class*="language-"]::-webkit-scrollbar-thumb, .snippet-box.response .snippet-body .code-toolbar pre[class*="language-"]::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: none;
  background-color: var(--code-response-details); }

.snippet-box.from-md .snippet-body .code-toolbar {
  max-width: 900px; }

.snippet-body .code-toolbar {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: calc(100% - .6rem); }
  @media (max-width: 1280px) {
    .snippet-body .code-toolbar {
      max-width: 800px; } }
  .snippet-body .code-toolbar .toolbar {
    display: flex;
    flex-direction: row;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
    order: 0;
    position: absolute;
    top: unset;
    bottom: calc(100% + .8rem);
    right: .5rem;
    margin: .5rem 0 .5rem 0; }
    .snippet-body .code-toolbar .toolbar .toolbar-item select {
      height: 30px;
      width: 80px;
      border-radius: 10px;
      border: 1px solid var(--code-api-details);
      color: var(--code-api-title);
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: url("../images/select-code.svg") 94%/15% no-repeat var(--code-api-bg);
      padding: 0 .3rem 0 .5rem;
      cursor: pointer;
      transition: all .3s; }
      .snippet-body .code-toolbar .toolbar .toolbar-item select:focus {
        outline: none; }
      .snippet-body .code-toolbar .toolbar .toolbar-item select:hover {
        border: 1px solid var(--accent-violet); }
      .snippet-body .code-toolbar .toolbar .toolbar-item select:active {
        border: 1px solid var(--code-response-details); }
        .snippet-body .code-toolbar .toolbar .toolbar-item select:active:focus {
          outline: none; }
    .snippet-body .code-toolbar .toolbar .toolbar-item span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      padding: 0 .5rem 0 .5rem;
      cursor: default; }
    .snippet-body .code-toolbar .toolbar .toolbar-item button {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      color: var(--text-section);
      background-color: var(--code-api-bg);
      border: 1px solid var(--code-api-bg);
      cursor: pointer;
      margin-left: .5rem;
      box-shadow: none;
      transition: all .3s; }
      .snippet-body .code-toolbar .toolbar .toolbar-item button:hover {
        border: 1px solid var(--accent-violet);
        overflow: visible;
        -webkit-overflow-scrolling: touch; }
      .snippet-body .code-toolbar .toolbar .toolbar-item button:focus {
        outline: none; }
      .snippet-body .code-toolbar .toolbar .toolbar-item button i {
        width: 30px;
        display: block;
        text-align: center;
        vertical-align: middle;
        position: absolute;
        font-size: 16px;
        color: var(--accent-azure);
        line-height: 30px;
        opacity: 0;
        right: -35px;
        transition: all .3s ease-in-out; }
        .snippet-body .code-toolbar .toolbar .toolbar-item button i.show {
          opacity: 1;
          right: -1px; }
          .snippet-body .code-toolbar .toolbar .toolbar-item button i.show.fa-check {
            color: var(--accent-green) !important; }
          .snippet-body .code-toolbar .toolbar .toolbar-item button i.show.fa-clipboard-list {
            color: var(--accent-azure) !important; }
  .snippet-body .code-toolbar code[class*="language-"], .snippet-body .code-toolbar pre[class*="language-"] {
    background: transparent;
    max-height: 500px;
    min-width: 0px;
    max-width: -webkit-fill-available;
    color: var(--syntax-main); }
  .snippet-body .code-toolbar code[class*="language-"]::-webkit-scrollbar-thumb, .snippet-body .code-toolbar pre[class*="language-"]::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--code-api-details);
    border: none; }
    .snippet-body .code-toolbar code[class*="language-"]::-webkit-scrollbar-thumb .token.selector, .snippet-body .code-toolbar code[class*="language-"]::-webkit-scrollbar-thumb .token.attr-name, .snippet-body .code-toolbar code[class*="language-"]::-webkit-scrollbar-thumb .token.string, .snippet-body .code-toolbar code[class*="language-"]::-webkit-scrollbar-thumb .token.char, .snippet-body .code-toolbar code[class*="language-"]::-webkit-scrollbar-thumb .token.builtin, .snippet-body .code-toolbar code[class*="language-"]::-webkit-scrollbar-thumb .token.inserted, .snippet-body .code-toolbar pre[class*="language-"]::-webkit-scrollbar-thumb .token.selector, .snippet-body .code-toolbar pre[class*="language-"]::-webkit-scrollbar-thumb .token.attr-name, .snippet-body .code-toolbar pre[class*="language-"]::-webkit-scrollbar-thumb .token.string, .snippet-body .code-toolbar pre[class*="language-"]::-webkit-scrollbar-thumb .token.char, .snippet-body .code-toolbar pre[class*="language-"]::-webkit-scrollbar-thumb .token.builtin, .snippet-body .code-toolbar pre[class*="language-"]::-webkit-scrollbar-thumb .token.inserted {
      color: var(--syntax-string); }
  .snippet-body .code-toolbar .line-numbers {
    order: 1;
    margin: 0;
    border-radius: 0; }
