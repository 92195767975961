* {
  margin: 0;
  padding: 0; }

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent; }
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  right: .5rem;
  background-color: transparent !important;
  z-index: 999; }
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 1px solid var(--code-response-details);
  background-color: var(--code-response-bg);
  transition: all .3s; }
::-webkit-scrollbar-corner {
  background-color: transparent; }

body {
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 300;
  line-height: 23px;
  background-color: var(--main-bg);
  color: var(--text-section);
  transition: all .3s;
  overflow: hidden;
  word-spacing: 2px;

  .postman-run-button {
    display: block;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 99;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .5);
    @media (max-width: 800px) {
      display: none; } }

  h1 {
    color: var(--title-section);
    text-align: left;
    margin: 1rem 0 1rem 0;
    font-weight: 200;
    line-height: 32px; }

  h2 {
    color: var(--subtitle-section);
    text-align: left;
    margin: 2rem 0 .5rem 0;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    @media (max-width: 800px) {
      margin: 4rem 0 .5rem 0; } }

  h3 {
    color: var(--title-section);
    text-align: left;
    margin: 2rem 0 .5rem 0;
    font-weight: 400;
    font-size: 16px; }

  h4 {
    color: var(--subtitle-section);
    text-align: left;
    margin: 2rem 0 .5rem 0;
    font-weight: 400;
    font-size: 14px; }

  h5 {
    color: var(--subtitle-section); }

  p, ul, ol {
    color: var(--text-section);
    text-align: justify;
    margin: 1rem 0 1rem 0; }

  ul {
    padding-left: 2rem;
    list-style: disc;
    display: flex;
    flex-direction: column; }

  ol {
    padding-left: 2rem;
    list-style: decimal;
    display: flex;
    flex-direction: column; }

  a {
    text-decoration: none;
    color: var(--accent-azure);
    transition: color .3s;
    cursor: pointer;
    word-break: break-word;
    font-weight: 300;
    &:hover {
      color: var(--accent-violet); } }

  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none !important; }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; }

  .api-version {
    position: fixed;
    top: -1px;
    right: 1rem; }

  .api-version {
    height: auto;
    width: 100%;
    max-width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 .3rem 0 .3rem;

    select#version {
      height: 50px;
      width: 90%;
      margin: 0 .5rem .5rem .5rem;
      flex-grow: 1;
      border: 1px solid var(--code-response-details);
      background-color: var(--code-response-bg);
      background: url('../images/select-version.svg') 97% / 22px no-repeat var(--code-api-bg);
      border-radius: 0 0 10px 10px;
      box-shadow: 0px 2px 6px 0px rgba(37, 39, 41, 0.5);
      padding-left: 1rem;
      padding-right: 2.5rem;
      color: var(--title-section);
      position: relative;
      // white-space: nowrap
      // overflow: hidden
      // text-overflow: ellipsis
      direction: ltr;
      text-align: left;
      &:focus {
        outline: none; }
      option {
        // white-space: nowrap
        // overflow: hidden
        // text-overflow: ellipsis
        direction: ltr;
        text-align: left;
        width: 100%; } } }

  .hide {
    display: none !important; }

  header {
    display: none;
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 100%;
      background-color: var(--main-bg);
      flex-shrink: 0;
      margin-top: 0px;
      position: fixed;
      z-index: 10;
      transition: all .4s;
      border-bottom: 1px solid var(--border-modules);
      &.large {
        .logo-mobile {
          opacity: 1; }
        .top-header {
          opacity: 1; } }
      &.small {
        margin-top: -45px;
        .logo-mobile {
          opacity: 0; }
        .top-header {
          opacity: 0; } }

      .logo-mobile {
        height: 30px;
        width: auto;
        margin: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s;
        &.svg path {
          fill: var(--text-section); } }

      .top-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
        position: relative;

        .api-version-mobile {
          height: auto;
          width: 100%;
          max-width: fit-content;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 .3rem 0 .3rem;

          select#version-mobile {
            height: 30px;
            width: 90%;
            margin: 0 .5rem .5rem .5rem;
            flex-grow: 1;
            border: 1px solid var(--code-response-details);
            background-color: var(--code-response-bg);
            background: url('../images/select-version.svg') 97% / 22px no-repeat var(--code-api-bg);
            border-radius: 10px;
            box-shadow: 0px 2px 6px 0px rgba(37, 39, 41, 0.5);
            padding-left: 1rem;
            padding-right: 2.5rem;
            color: var(--title-section);
            position: relative;
            // white-space: nowrap
            // overflow: hidden
            // text-overflow: ellipsis
            direction: ltr;
            text-align: left;
            &:focus {
              outline: none; }
            option {
              // white-space: nowrap
              // overflow: hidden
              // text-overflow: ellipsis
              direction: ltr;
              text-align: left;
              width: 100%; } } } }

      .top-mobile {
        height: 3.3rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 .3rem 0 .3rem;

        select#mobile-menu {
          height: 30px;
          width: 90%;
          margin: .5rem;
          flex-grow: 1;
          border: 1px solid var(--code-response-details);
          background-color: var(--code-response-bg);
          background: url('../images/select-menu.svg') 3% / 22px no-repeat var(--code-api-bg);
          border-radius: 10px;
          padding-left: 2.5rem;
          color: var(--title-section);
          text-transform: uppercase;
          position: relative;
          // white-space: nowrap
          // overflow: hidden
          // text-overflow: ellipsis
          direction: ltr;
          text-align: left;
          &:focus {
            outline: none; }
          option {
            // white-space: nowrap
            // overflow: hidden
            // text-overflow: ellipsis
            direction: ltr;
            text-align: left;
            width: 100%; } }

        .cta-mobile {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;

          .top-search-mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 0 0 .5rem;
            width: calc(100% - 2rem);

            button.search-trigger-mobile {
              height: 30px;
              width: 30px;
              display: flex;
              justify-content: center;
              padding: .5rem;
              margin: .5rem;
              align-items: center;
              border: 1px solid var(--code-response-details);
              background-color: var(--code-response-bg);
              border-radius: 10px;
              color: var(--title-section);
              cursor: pointer;
              transition: all .3s;
              &:hover {
                border: 1px solid var(--code-response-bg); }
              &:active {
                border: 1px solid var(--code-response-details); }
              &:focus {
                outline: none; } } }

          .theme-switch-wrapper {
            display: flex;
            align-items: center;
            margin: .5rem;

            .theme-switch {
              display: inline-block;
              height: 14px;
              position: relative;
              width: 36px;

              input[type="checkbox"] {
                display: none; }

              .slider {
                background-color: var(--code-response-bg);
                border: 1px solid var(--code-response-details);
                bottom: 0;
                cursor: pointer;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition: .4s; }

              .slider:before {
                background-color: var(--main-bg);
                bottom: -5px;
                height: 20px;
                left: -3px;
                position: absolute;
                transition: .3s;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center; }

              input:checked + .slider {
                background-color: var(--code-response-bg); }

              input:checked + .slider:before {
                transform: translateX(21px);
                content: "\f185";
                color: var(--accent-yellow);
                font-family: 'Font Awesome 5 Free'; }

              .slider.round {
                border-radius: 8px; }

              .slider.round:before {
                border-radius: 50%;
                border: 1px solid var(--code-response-details);
                content: "\f186";
                color: var(--accent-violet);
                font-family: 'Font Awesome 5 Free'; } } } } } } }

  main {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: 600px;
    // @media (max-width: 800px)
    //   padding-top: 4rem

    #sidebar, #sidebar-changelog {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      // height: 100%
      min-height: 100%;
      min-width: 260px;
      width: 260px;
      border-right: 1px solid var(--border-modules);
      border-bottom: 1px solid var(--border-modules);

      @media (max-width: 800px) {
        display: none; }

      .top {
        height: auto;
        width: calc(100% - .6rem);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 .3rem 0 .3rem;

        .logo {
          height: 30px;
          width: auto;
          margin: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          &.svg path {
            fill: var(--text-section); } }

        .theme-switch-wrapper {
          display: flex;
          align-items: center;
          margin: .5rem;

          .theme-switch {
            display: inline-block;
            height: 14px;
            position: relative;
            width: 36px;

            input[type="checkbox"] {
              display: none; }

            .slider {
              background-color: var(--code-response-bg);
              border: 1px solid var(--code-response-details);
              bottom: 0;
              cursor: pointer;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              transition: .4s; }

            .slider:before {
              background-color: var(--main-bg);
              bottom: -5px;
              height: 20px;
              left: -3px;
              position: absolute;
              transition: .3s;
              width: 20px;
              display: flex;
              justify-content: center;
              align-items: center; }

            input:checked + .slider {
              background-color: var(--code-response-bg); }

            input:checked + .slider:before {
              transform: translateX(21px);
              content: "\f185";
              color: var(--accent-yellow);
              font-family: 'Font Awesome 5 Free'; }

            .slider.round {
              border-radius: 8px; }

            .slider.round:before {
              border-radius: 50%;
              border: 1px solid var(--code-response-details);
              content: "\f186";
              color: var(--accent-violet);
              font-family: 'Font Awesome 5 Free'; } } } }

      .side-search {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem 0 1rem;
        margin-bottom: .5rem;
        width: calc(100% - 2rem);
        border-bottom: 1px solid var(--border-modules);
        button.search-trigger {
          height: 30px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: .5rem;
          margin: 1rem 0 1rem 0;
          align-items: center;
          border: 1px solid var(--code-response-details);
          background-color: var(--code-response-bg);
          border-radius: 10px;
          color: var(--title-section);
          cursor: pointer;
          transition: all .3s;
          &:hover {
            border: 1px solid var(--code-response-bg); }
          &:active {
            border: 1px solid var(--code-response-details); }
          &:focus {
            outline: none; } } }

      .sidebar-left-collapse {
        width: calc(100% - .3rem);
        height: 100%;
        padding: 0;
        margin: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        .sidebar-links {
          display: flex;
          flex-direction: column;
          width: calc(100% - .6rem);
          margin: 30px auto;
          .link {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: auto;
            width: 100%;
            &.selected a {
              color: var(--title-section);
              line-height: 1.5;
              margin: .5rem 0 .5rem 0;
              padding: .3rem 0 .3rem 0;
              border: 1px solid var(--accent-violet);
              border-radius: 22px;
              i {
                color: var(--title-section); } }
            a {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              text-decoration: none;
              margin: .25rem 0 .25rem 0;
              padding: .1rem 0 .1rem 0;
              text-align: left;
              color: var(--text-section);
              font-size: 14px;
              line-height: 2;
              border: 1px solid var(--code-response-details);
              background-color: var(--code-response-bg);
              border-radius: 20px;
              transition: all .3s;
              &:hover {
                border: 1px solid var(--accent-violet);
                color: var(--title-section); }
              &:active {
                border: 1px solid var(--accent-violet);
                color: var(--text-section);
                i {
                  color: var(--text-section); } }
              svg {
                margin: 0 1rem 0 1rem;
                color: var(--title-section); }
              i {
                margin: 0 0 0 1rem;
                color: var(--text-section); }
              span {
                margin-left: 1rem;
                display: flex;
                flex-wrap: wrap; } }

            ul.sub-links {
              display: flex;
              flex-direction: column;
              max-height: 0;
              width: 100%;
              overflow: hidden;
              list-style: none;
              background-color: transparent;
              color: var(--text-section);
              font-size: 12px;
              line-height: 24px;
              padding: 0;
              margin: 0;
              transition: 0.5s;
              li {
                background-color: transparent;
                margin: 0;
                &.selected a {
                  color: var(--text-title);
                  background-color: var(--code-response-bg); }
                a {
                  text-decoration: none;
                  background-color: transparent;
                  color: var(--text-section);
                  display: block;
                  padding: .3rem 0 .3rem 0;
                  margin: .3rem 0 .3rem 0;
                  border: 0;
                  text-align: left;
                  background-color: var(--main-bg);
                  border-radius: 20px;
                  transition: all .3s;
                  &:hover {
                    color: var(--text-title);
                    background-color: var(--code-response-bg); }
                  &:active {
                    color: var(--text-section);
                    background-color: var(--code-response-bg); }
                  span {
                    margin-left: 1rem;
                    display: flex;
                    flex-wrap: wrap; } } } }

            &.selected ul.sub-links {
              max-height: 2000px; } } } }

      .copyright {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 32px;
        width: 260px;
        border-top: 1px solid var(--border-modules);
        span {
          font-size: 12px;
          color: var(--text-section); } } }

    #content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      padding-top: 2rem;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      // box-sizing: border-box

      .api-section {
        width: 100%;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 0 1rem 0;
        border-bottom: 1px solid var(--border-modules);

        .api-module {
          width: 100%;
          max-width: calc(1264px + 10vw);
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          // border-bottom: 1px solid var(--border-modules)
          // margin: 5rem 0 5rem 0
          padding: 4rem 0 4rem 0;
          // position: relative
          // overflow-y: scroll

          @media (max-width: 1280px) {
            flex-direction: column; }

          @media (max-width: 800px) {
            padding: 2rem 0 4rem 0; }

          table {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: auto;
            width: 100%;
            max-width: 900px;
            margin: 1rem 2.5rem 1rem 2.5rem;
            padding: 0;
            @media (max-width: 1280px) {
              margin: 1rem 0 1rem 0; }
            thead {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              padding: .3rem 0 .3rem 0;
              border-radius: 10px 10px 0 0;
              border: 1px solid var(--code-api-details);
              background-color: var(--code-api-details);
              tr {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                th {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  min-width: 33%;
                  margin: .5rem; } } }
            tbody {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              flex-direction: column;
              flex-grow: 1;
              width: 100%;
              height: auto;
              // max-height: 600px
              position: relative;
              padding: 1rem 0 1rem 0;
              border-radius: 0 0 10px 10px;
              border: 1px solid var(--code-api-details);
              background-color: var(--code-api-bg);
              tr {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                td {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  text-align: justify;
                  min-width: 33%;
                  margin: .5rem;
                  code {
                    word-break: break-all; } } } } }


          .text-side {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            // width: 50%
            width: 100%;
            flex-shrink: 1;
            height: 100%;
            padding: 0 0 3rem 0;

            @media (max-width: 1280px) {
              width: 100%; }

            .text-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              height: auto;
              width: 90%;
              max-width: 600px;
              margin: 0 2.5rem 0 2.5rem;
              font-size: 14px;
              h1 {
                margin-top: 3rem; }
              &.changelog, &.full-centered {
                max-width: 1200px;
                margin: 2rem 0 2rem 0;
                @media (max-width: 800px) {
                  ul {
                    padding: 0 0 0 1.5rem;
                    max-width: 100%;
                    li {
                      width: 100%;
                      p {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        text-align: left;
                        flex-wrap: wrap;
                        line-height: 24px;
                        width: 100%; } } } } }

              @media (max-width: 1280px) {
                max-width: 800px; }

              code[class*="language-"], pre[class*="language-"] {
                background: var(--code-response-bg);
                border: 1px solid var(--code-response-details);
                color: var(--code-response-title);
                margin: 0 .3rem 0 0;
                font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace; }

              code[class*="language-"] {
                padding: 0 .5rem 0 .5rem; }
              pre[class*="language-"] {
                padding: 0; }

              ul.argattr-box {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: auto;
                width: 100%;
                margin: 1rem 0 1rem 0;
                padding: 0;
                li {
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  width: 100%;
                  height: auto;
                  padding: 1rem 0 1rem 0;
                  &.title {
                    text-transform: uppercase;
                    border-bottom: 1px solid var(--border-modules);
                    h5 {
                      font-size: 12px;
                      font-weight: 300; } }
                  &.child {
                    @media (max-width: 800px) {
                      flex-direction: column;
                      h3 {
                        flex-direction: row; } } }
                  h3 {
                    width: 100%;
                    max-width: 300px;
                    padding-right: 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin: 0;
                    span.label {
                      font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
                      margin: 0 .5rem 0 0;
                      font-size: 13px;
                      color: var(--attribute-label);
                      font-weight: bolder; }
                    span.validation {
                      margin: .2rem 0 .2rem 0;
                      font-size: 12px;
                      font-weight: 300;
                      line-height: 16px; } }

                  .description > ul > li:before {
                    content: '◦';
                    margin-right: 1rem; }

                  .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-grow: 1;
                    width: 100%;

                    p {
                      margin: 0 0 1rem 0;
                      flex-wrap: wrap;
                      word-break: break-word; }

                    .answer {
                      display: block;
                      height: auto;
                      margin: 0;
                      width: 100%;

                      &.question {
                        position: relative;
                        width: 100%;
                        padding: .5rem 0 .5rem 0;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        span {
                          color: var(--title-section);
                          flex-grow: 1; }

                        i {
                          color: var(--accent-violet);
                          transform: rotate(0deg);
                          transition: all .3s;
                          font-size: 16px;
                          font-family: 'Font Awesome 5 Free'; }

                        &:after {
                          display: block;
                          content: "";
                          position: absolute;
                          top: 100%;
                          left: 0;
                          width: 100%;
                          border: 0px solid var(--border-modules);
                          transition: all .3s ease; } }

                      &__radio:checked ~ .answer.question:after {
                        top: 0; }

                      &__radio:checked ~ .answer.content {
                        max-height: 10000px; }

                      &__radio:checked ~ .answer.question i {
                        transform: rotate(45deg);
                        color: lightcoral; }

                      &.content {
                        font-size: 16px;
                        border-bottom: 1px solid var(--border-modules);
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height .5s ease;

                        .answer.content-inner {
                          padding: 1rem 0 1rem 0;

                          p:not(:first-child), ul:not(:first-child) {
                            margin: 1em 0; }

                          p:last-child, ul:last-child {
                            margin-bottom: 0; }

                          ul.accordion {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            height: auto;
                            width: auto;
                            margin: 1rem 0 1rem 0;
                            padding: 0;
                            background-color: var(--code-response-details);
                            border: 1px solid var(--border-modules);
                            border-radius: 10px;
                            list-style: none;
                            li {
                              display: flex;
                              justify-content: center;
                              align-items: flex-start;
                              flex-direction: column;
                              width: calc(100% - 2rem);
                              height: auto;
                              padding: .5rem 1rem .5rem 1rem;
                              background-color: var(--code-response-details);
                              font-size: 13px;
                              &:nth-child(odd) {
                                background-color: var(--code-response-bg); }
                              &:first-child {
                                border-radius: 10px 10px 0 0; }
                              &:last-child {
                                border-radius: 0 0 10px 10px; }
                              &:only-child {
                                border-radius: 10px 10px 10px 10px; }
                              // &.title-accordion
                              //   text-transform: uppercase
                              //   border-bottom: 1px solid var(--border-modules)
                              //   border-radius: 10px 10px 0 0
                              //   background-color: transparent
                              //h5
                              //  text-align: left
                              //  word-break: break-word
                              //  min-width: 90px
                              //  width: 100%
                              //  font-size: 12px
                              //  font-weight: 300
                              //  padding-left: 1rem
                              .top-item {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                width: 100%;
                                font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
                                span.detail {
                                  margin: 0 .2rem 0 .5rem;
                                  font-size: 12px;
                                  font-weight: 500; }
                                span.label {
                                  font-weight: bolder;
                                  color: var(--attribute-label); } }

                              .bottom-item {
                                width: 100%;
                                ul {
                                  padding: 0px; } }

                              ul {
                                margin: 1rem auto 1rem auto;
                                li {
                                  background-color: var(--code-response-bg);
                                  border-bottom: 1px solid var(--border-modules); } } } } } } } } } }

              ul.changelog {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: auto;
                width: 100%;
                max-width: 900px;
                margin: 1rem 0 1rem 0;
                padding: 0;
                border: 1px solid var(--border-modules);
                border-radius: 10px;
                list-style: none;
                li {
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-start;
                  width: 100%;
                  height: auto;
                  padding: 1rem 0 1rem 0;
                  background-color: var(--code-response-details);
                  &:nth-child(odd) {
                    background-color: var(--code-response-bg); }
                  &:last-child {
                    border-radius: 0 0 10px 10px; }
                  &.title-changelog {
                    text-transform: uppercase;
                    border-bottom: 1px solid var(--border-modules);
                    border-radius: 10px 10px 0 0;
                    background-color: transparent;
                    h5 {
                      text-align: left;
                      word-break: break-word;
                      min-width: 90px;
                      width: 100%;
                      font-size: 12px;
                      font-weight: 300;
                      padding-left: 1rem; } }
                  span {
                    font-weight: 500;
                    width: 100%;
                    min-width: 120px;
                    text-align: left;
                    word-break: break-word;
                    padding-left: 1rem; } } } } }

          .snippet-side {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            align-self: flex-start;
            max-width: 50%;
            flex-grow: 1;
            height: auto;
            padding: 4rem 0 4rem 0;
            position: sticky;
            top: 0;

            @media (max-width: 1280px) {
              width: 100%;
              max-width: 100%; } }

          .snippet-side, .text-side {

            .snippet-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 90%;
              max-width: 600px;
              min-width: 300px;
              height: auto;
              margin: 1rem 2.5rem 1rem 2.5rem;
              background-color: var(--code-api-bg);
              border-radius: 10px;
              box-shadow: 0 4px 8px 0px rgba(0, 0, 0, .2);
              // &.response
              //   background-color: var(--code-response-bg)
              //   .snippet-head
              //     border: 1px solid var(--code-response-details)
              //     background-color: var(--code-response-details)
              //     p
              //       color: var(--code-response-title)
              //   .snippet-body
              //     border: 1px solid var(--code-response-details)
              //     background-color: var(--code-response-bg)
              &.from-md {
                max-width: 900px; }

              @media (max-width: 1280px) {
                max-width: 800px;
                margin: 1rem 0 1rem 0;
                width: 90%;
                min-width: 100px;
                &.from-md {
                  width: 100%;
                  max-width: 100%; } }

              .snippet-head {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                border-radius: 10px 10px 0 0;
                border: 1px solid var(--code-api-details);
                background-color: var(--code-api-details);
                p.response, p.path {
                  margin: .5rem 0 .5rem .5rem;
                  color: var(--code-api-title);
                  // letter-spacing: .1rem
                  white-space: pre;
                  text-overflow: ellipsis;
                  width: calc(100% - 180px);
                  overflow: hidden; }
                span.method {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: .5rem 0 .5rem .5rem;
                  font-weight: bolder;
                  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
                  &.get {
                    color: var(--method-get); }
                  &.head {
                    color: var(--method-head); }
                  &.post {
                    color: var(--method-post); }
                  &.put {
                    color: var(--method-put); }
                  &.delete {
                    color: var(--method-delete); }
                  &.connect {
                    color: var(--method-connect); }
                  &.option {
                    color: var(--method-option); }
                  &.trace {
                    color: var(--method-trace); }
                  &.patch {
                    color: var(--method-patch); } } }

              .snippet-body {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-grow: 1;
                width: 100%;
                height: auto;
                max-height: 600px;
                position: relative;
                // overflow-x: hidden
                // overflow-y: visible
                padding: 1rem 0 1rem 0;
                border-radius: 0 0 10px 10px;
                border: 1px solid var(--code-api-details);
                background-color: var(--code-api-bg);
                &.no-head {
                  border-radius: 10px;
                  .code-toolbar pre {
                    border: 0 !important;
                    code {
                      border: 0 !important; } } }

                .copy-tooltip {
                  height: auto;
                  width: auto;
                  padding: .3rem 1.5rem .3rem 1rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid var(--border-modules);
                  border-radius: 20px;
                  background-color: var(--code-response-bg);
                  position: absolute;
                  top: -74px;
                  right: 7px;
                  opacity: 0;
                  transition: all .2s ease;
                  &:after {
                    content: "";
                    position: absolute;
                    bottom: -6px;
                    right: 15px;
                    width: 0;
                    height: 0;
                    border-left: solid 6px transparent;
                    border-right: solid 6px transparent;
                    border-top: solid 6px var(--border-modules);
 }                    // background-color: var(--code-response-bg)
                  &.appear {
                    top: -84px;
                    opacity: 1; }
                  span {
                    font-size: 12px; } }

                // .code-toolbar .toolbar .toolbar-item button:hover > .copy-tooltip
                //   opacity: 1
                //   background-color: var(--accent-violet)

                .code-toolbar {
                  .token {
                    word-break: keep-all; } } } } } } } } }

  // footer
  //   display: flex
  //   justify-content: center
  //   align-items: center
  //   min-height: 50px
  //   width: 260px
  //   border-right: 1px solid var(--border-modules)
  //   .copyright
  //     display: flex
  //     flex-direction: column
  //     justify-content: center
  //     align-items: center
  //     min-height: 50px
  //     width: 260px
  //     border-right: 1px solid var(--border-modules)
  //     span
  //       font-size: 12px
  //       color: var(--text-section)

  //   @media (max-width: 800px)
 }  //     width: 100%
